import React from "react"

export const faqData = [
  {
    question: "Can I become a Mentor?",
    answer:
      "Ideally with at least one year of experience, including internships at an MNC, PBC, or one of the top tech startups, you are most welcome to apply to be a mentor.",
  },
  {
    question: "How to Become a Mentor?",
    answer:
      "Fill up the application form, and apply to be a mentor. The team will review and you will know the status within 2-4 days.",
  },
  {
    question: "How can I build my Community?",
    answer: (
      <span>
        Once you're logged in as a Mentor on the App, you'll be able to create
        your first community post. Read more about guidelines{" "}
        <a href="https://blog.mentro.tech/release-b3/" target={"_blank"}>
          here
        </a>
      </span>
    ),
  },
  {
    question: "Why should I build my Community on Mentro?",
    answer:
      'Gone are the days, when you used to share Telegram links and people used to say "Link is not working" and they had to download Telegram first manually. With Mentro community links, people can join directly. No Drop-off! Moreover, grow with a fresh audience on Mentro. Create and share more in your community, and watch it grow 🙌',
  },
  {
    question: "How can I Monetize?",
    answer:
      "Set up your Calendar with your availability and create slots for what you want to do right away. Put your own pricing and charge as you wish. We don't have any boring criteria like 1,000 subs, blah, blah. Earn from Day1 😎",
  },
  {
    question: "What can I Monetize?",
    answer:
      "Launch 1-Day Masterclass, 2/3 Day Workshops, 3-4 Week Bootcamps, CBCs, Recorded Courses and more. You just launch and think about quality content, we help you monetize 💰.",
  },
]
