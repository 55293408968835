import React from "react"
import { Typography, Carousel } from "antd"
import "./Covers.less"
import CoversEmoji from "../../../assets/mentor/coversEmoji.svg"
import CoverDesign from "../../../assets/mentor/design.svg"
import YajasCover from "../../../assets/mentor/yajasCover.png"
import BhupenderCover from "../../../assets/mentor/bhupenderCover.png"
import RutvikaCover from "../../../assets/mentor/rutvikaCover.png"

const { Title } = Typography

export default function Introduction() {
  return (
    <div className="coversDiv">
      <img src={CoverDesign} alt="CoverDesign" className="coverDesign" />
      <div className="headDiv">
        <Title className="title">Mentro covers</Title>
        <img src={CoversEmoji} alt="emoji with glass" />
      </div>
      <div className="carouselDiv">
        <Carousel
          autoplay={true}
          className="carousel"
          appendDots={dots => <ul className="normal-dot">{dots}</ul>}
        >
          <img src={BhupenderCover} alt="User" className="image" />
          <img src={YajasCover} alt="User" className="image" />
          <img src={RutvikaCover} alt="User" className="image" />
        </Carousel>
      </div>
    </div>
  )
}
