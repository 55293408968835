import React, { useState } from "react"
import { Drawer, Button } from "antd"
import RightMenu from "./RightMenu"
import "./Navbar.less"
import MentroLogo from "../../../assets/mentor/mentroLogo.svg"
import HamburgerMenu from "../../../assets/mentor/hamburgerMenu.svg"
import { logGa } from "../../../services/analytics"

function Navbar() {
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  return (
    <nav className="mainNavbarParent">
      <div className="mainNavbar">
        <div className="logoDiv">
          <a href="/">
            <img
              src={MentroLogo}
              alt="Mentro Logo"
              onClick={() => logGa("mentor_landing_navbar_logo")}
            />
          </a>
        </div>
        <div>
          <div className="hideRightMenu">
            <RightMenu close={onClose} />
          </div>
          <Button type="primary" onClick={showDrawer} className="drawerBtn">
            <img src={HamburgerMenu} alt="menu icon" />
          </Button>
          <Drawer
            placement="right"
            closable={false}
            onClose={onClose}
            visible={visible}
          >
            <RightMenu close={setVisible} />
          </Drawer>
        </div>
      </div>
    </nav>
  )
}

Navbar.propTypes = {}

export default Navbar
