import React from "react"
import { Typography, Row, Col } from "antd"
import "./Fact2.less"
import Group2 from "../../../../assets/mentor/group2.svg"

const { Title, Text } = Typography

export default function Fact2() {
  return (
    <Row className="fact2Div">
      <Col lg={12} md={12} className="rightCol">
        <img src={Group2} alt="Group 2" />
      </Col>
      <Col lg={12} md={12} className="leftCol">
        <Title className="title">
          Grow your <span>Brand</span>
        </Title>
        <Text className="text">
          Impact by sharing content, and resources in your community. Set up
          your Mentor profile, and Earn $$. It's time you monetize your skills
          and experience. <br />
        </Text>
      </Col>
    </Row>
  )
}
