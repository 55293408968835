import React from "react"
import { Button } from "antd"
import "./RightMenu.less"
import Apply from "../../../assets/mentor/apply.svg"
import Home from "../../../assets/mentro/home.svg"
import Community from "../../../assets/mentor/joinCommunity.svg"
import { Link } from "gatsby"
import clsx from "clsx"
import { logGa } from "../../../services/analytics"

const RightMenu = ({ close }) => {
  return (
    <div className="rightMenu">
      <Link to="/" style={{ fontSize: "1em", margin: "0em" }}>
        <Button
          className={clsx([
            "navBtn",
            typeof window !== "undefined" &&
              window &&
              window.location.pathname === "/" &&
              "link-select",
          ])}
          type="text"
          onClick={() => {
            logGa("mentor_landing_home_navbar")
            close(false)
          }}
        >
          <img src={Home} alt="home" />
          Home
          <hr />
        </Button>
      </Link>
      <Link to="/mentor/apply" style={{ fontSize: "1em", margin: "0em" }}>
        <Button
          className={clsx([
            "navBtn",
            typeof window !== "undefined" &&
              window &&
              window.location.pathname === "/mentor/apply/" &&
              "link-select",
          ])}
          type="text"
          onClick={() => {
            logGa("mentor_landing_apply_navbar")
            close(false)
          }}
        >
          <img src={Apply} alt="Apply" />
          Be a Mentor
          <hr />
        </Button>
      </Link>
      <Button
        className="navBtn"
        type="text"
        onClick={() => {
          logGa("mentor_landing_community_navbar")
          close(false)
          window.open(`${process.env.GATSBY_HOME_URL}/Discord`)
        }}
      >
        <img src={Community} alt="Community" />
        Community
        <hr />
      </Button>
      <Button
        className="downloadBtn"
        type="text"
        onClick={() => {
          logGa("mentor_landing_download_app_navbar")
          window.open(process.env.GATSBY_PLAYSTORE_LINK)
          close(false)
        }}
      >
        Download app
      </Button>
    </div>
  )
}

export default RightMenu
