import React, { useState } from "react"
import { Typography } from "antd"
import "./Mentors.less"
import { mentorsData } from "./mentorsData.js"

const { Title, Text } = Typography

export default function Mentors() {
  const [mentors, setMentors] = useState(mentorsData)
  const [centralMentor, setCentralMentor] = useState(() =>
    mentorsData.find(m => m.id === 1)
  )

  const changeMentor = (e, mentor) => {
    if (e) e.preventDefault()
    let mNew = { ...mentor, id: centralMentor.id }
    let cNew = { ...centralMentor, id: mentor.id }

    let newMentor = mentors.map(m => {
      if (m.id === 1) {
        return mNew
      } else {
        if (m.id === mentor.id) {
          return cNew
        } else {
          return m
        }
      }
    })

    setMentors(newMentor)
    setCentralMentor(mNew)
  }

  return (
    <div className="mentorsParent">
      <div className="headDiv">
        <Title className="title">What our mentors say</Title>
      </div>
      <div className="mentorsDiv">
        {mentors.map(mentor => {
          return (
            <img
              key={mentor.id}
              src={mentor.image}
              onClick={e => changeMentor(e, mentor)}
              className={`mentor${mentor.id}`}
              alt="mentors"
            />
          )
        })}
        <Title className="mentorName">{centralMentor.name}</Title>
      </div>
      <div className="mentorsTalkDiv">
        <Text className="text">
          <span className="startQuote">“</span>
          <br />
          {centralMentor.saying}
          <span className="endQuote">”</span>
        </Text>
      </div>
    </div>
  )
}
