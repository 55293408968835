export const mentorsData = [
  {
    id: 1,
    name: "Aanshul Sadaria",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mentro-app.appspot.com/o/website-assets%2Fsadaria.jpeg?alt=media&token=f5119b47-c7a1-41eb-a479-3bf3640de989",
    saying:
      "Mentro has been a very great experience as a mentor. Helping students with placements, resume review, technical interviews, and any kind of doubts at one place is a great experience for students as well. The best part is that they provide resources of all kinds at absolutely no cost and these sessions are provided at affordable rates.",
  },
  {
    id: 2,
    name: "Shivam Goyal",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mentro-app.appspot.com/o/users%2F6oZ6xzMZB2bQXxezvuvHuWqDD9l2%2Fprofile.jpg?alt=media&token=11bdc4bc-ece4-4ff9-a542-bea13b3a0c00",
    saying:
      "Mentro is an outstanding tool for people like me whose daily fuel is helping others in whatever way possible. Being an avid advocate of tech literacy, Mentro gave me a platform to share experiences with those in need.",
  },
  {
    id: 3,
    name: "Anubhav Sinha",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mentro-app.appspot.com/o/users%2FAzvpxRQyHXV7yexwDRZtaTGosr12%2Fprofile.jpg?alt=media&token=2a59d38f-b3dc-4e86-9460-bea0a6929435",
    saying:
      "Mentro is really a good platform and the work they are doing is really commendable. Now students can easily get their mentors and get any sort of guidance for their career easily. And I believe a mentor is instrumental in one's growth.",
  },
  {
    id: 4,
    name: "Jaideep Prasad",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mentro-app.appspot.com/o/users%2FEKQ2A2OH7RaKAHPJGvIxnuDT5rx1%2Fprofile.jpg?alt=media&token=23688e07-11a4-47dc-9606-e196f5197c21",
    saying:
      "Mentro gave me the chance to do the impactful work and meet aspiring potential minds. I am content that I could help fulfilling some dreams - One engineer at a time!",
  },
  {
    id: 5,
    name: "Aditya Gupta",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mentro-app.appspot.com/o/users%2FIcHUWvI4hZexFnqTSJF5baMvpZf1%2Fprofile.jpg?alt=media&token=a47023a7-f27f-4385-8159-6b6c04d01f4b",
    saying:
      "Mentro gave me the chance to do the impactful work and meet aspiring potential minds. I am content that I could help fulfilling some dreams - One engineer at a time!",
  },
  {
    id: 6,
    name: "Mukul Kumar",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mentro-app.appspot.com/o/users%2Fb5mB7TZ9EJUCW48A0f63UL8thWa2%2Fprofile.jpg?alt=media&token=ddd06104-803c-4899-a619-cbc113cb4751",
    saying:
      "Mentro gave me the chance to do the impactful work and meet aspiring potential minds. I am content that I could help fulfilling some dreams - One engineer at a time!",
  },
  {
    id: 7,
    name: "Rutvika Patil",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mentro-app.appspot.com/o/users%2Fa11YPENxeIRFIa2zazZscwejpog1%2Frutvika.jpg?alt=media&token=0ea11ecd-c200-4920-8256-fab96957a774",
    saying:
      "Mentro gave me the chance to do the impactful work and meet aspiring potential minds. I am content that I could help fulfilling some dreams - One engineer at a time!",
  },
  {
    id: 8,
    name: "Prudhvi Reddy",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mentro-app.appspot.com/o/users%2FVtruKfYwd4ehv3zWV0bl766oyt23%2Fprofile.jpg?alt=media&token=3c4cb833-edf8-4b60-bfad-16b82700ea34",
    saying:
      "Mentro is a place where students can meet the people who they want to be in near future. Students get customised path and complete package of everything that they need to be a rockstar in their domains or get placed at their dream companies.",
  },
]
