import React from "react"
import Fact1 from "./Fact1"
import Fact2 from "./Fact2"
import Fact3 from "./Fact3"

export default function Facts() {
  return (
    <>
      <Fact1 />
      <Fact2 />
      <Fact3 />
    </>
  )
}
