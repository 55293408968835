import React, { useEffect } from "react"
import Seo from "../components/seo"
import Container from "../components/MentorLanding/Container"
import Covers from "../components/MentorLanding/Covers"
import Facts from "../components/MentorLanding/Facts"
import Faq from "../components/MentorLanding/FAQ"
import Introduction from "../components/MentorLanding/Introduction"
import Mentors from "../components/MentorLanding/Mentors"
import MentorsFrom from "../components/MentorLanding/MentorsFrom"
import Session from "../components/MentorLanding/Session"
import "../theme/mentors.less"

const MentorsPage = () => {
  // useEffect(() => {
  //   //TAWK.to
  //   var Tawk_API = Tawk_API || {}

  //   var s1: any = document.createElement("script")
  //   var s0: any = document.getElementsByTagName("script")[0]
  //   s1.async = true
  //   s1.src = "https://embed.tawk.to/622f1fada34c2456412af169/1fu417g0k"
  //   s1.charset = "UTF-8"
  //   s1.setAttribute("crossorigin", "*")
  //   s0.parentNode.insertBefore(s1, s0)
  // }, [])

  return (
    <Container>
      <Seo title="Build your community and Be a Mentor." description="Create and share Learning Content of your niche. Start building your Community & be their Mentor today! Be able to monetize with in-house services and Earn as you Impact." />
      <Introduction />
      <MentorsFrom />
      <Facts />
      <Covers />
      <Mentors />
      <Session />
      <Faq />
    </Container>
  )
}

export default MentorsPage
