import React, { useState, useLayoutEffect, useCallback } from "react"
import styled from "styled-components"

interface RippleDivProps {
  color: string
  duration: number
}

interface RippleProps {
  x: number
  y: number
  size: number
}

const RippleContainer = styled.div<RippleDivProps>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  span {
    transform: scale(0);
    border-radius: 100%;
    position: absolute;
    opacity: 0.75;
    background-color: ${props => props.color};
    animation-name: ripple;
    animation-duration: ${props => props.duration}ms;
  }
  @keyframes ripple {
    to {
      opacity: 0;
      transform: scale(2);
    }
  }
`

const useDebouncedRippleCleanUp = (
  rippleCount: number,
  duration: number,
  cleanUpFunction: () => void
) => {
  useLayoutEffect(() => {
    let bounce: NodeJS.Timeout | null = null
    if (rippleCount > 0) {
      if (bounce) {
        clearTimeout(bounce)
      }

      bounce = setTimeout(() => {
        cleanUpFunction()
        if (bounce) clearTimeout(bounce)
      }, duration * 4)
    }

    return () => {
      if (bounce) clearTimeout(bounce)
    }
  }, [rippleCount, duration, cleanUpFunction])
}

const Ripple = ({ duration = 850, color = "#5cc00b" }) => {
  const [rippleArray, setRippleArray] = useState<RippleProps[]>([])

  useDebouncedRippleCleanUp(rippleArray.length, duration, () => {
    setRippleArray([])
  })
  const addRipple = useCallback(
    event => {
      const rippleContainer = event.nativeEvent.target.getBoundingClientRect()
      const size =
        rippleContainer.width > rippleContainer.height
          ? rippleContainer.width
          : rippleContainer.height
      const x = event.pageX - rippleContainer.x - size / 2
      const y = event.pageY - rippleContainer.y - size / 2
      const newRipple: RippleProps = {
        x,
        y,
        size,
      }

      setRippleArray([...rippleArray, newRipple])
    },
    [rippleArray]
  )
  return (
    <RippleContainer duration={duration} color={color} onMouseDown={addRipple}>
      {rippleArray.length > 0 &&
        rippleArray.map((ripple: RippleProps) => (
          <span
            key={`span${ripple.y}${ripple.x}`}
            style={{
              top: ripple.y,
              left: ripple.x,
              width: ripple.size,
              height: ripple.size,
            }}
          />
        ))}
    </RippleContainer>
  )
}

export default Ripple
