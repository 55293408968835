import React from "react"
import { Typography, Row, Col } from "antd"
import "./Fact3.less"
import Group3 from "../../../../assets/mentor/group3.svg"
import GreenTick from "../../../../assets/mentor/greenTick.svg"

const { Title, Text } = Typography

export default function Fact3() {
  return (
    <Row className="fact3Div">
      <Col lg={14} md={12} className="leftCol">
        <Title className="title">
          Perks of being a part of the <span>Mentro Community</span>.
        </Title>
        <Row className="points">
          <img src={GreenTick} alt="check mark" />
          <Text className="text">
            Exclusive Mentor Slack community for professionals to talk and share
            experiences. Networking OP!
            <br />
          </Text>
        </Row>
        <Row className="points">
          <img src={GreenTick} alt="check mark" />
          <Text className="text">
            Get invited for speaking at conferences & sessions across the
            country
            <br />
          </Text>
        </Row>
        <Row className="points">
          <img src={GreenTick} alt="check mark" />
          <Text className="text">
            Get recognized among other communities across colleges in India
            <br />
          </Text>
        </Row>
        <Row className="points">
          <img src={GreenTick} alt="check mark" />
          <Text className="text">
            We send you fully customized{" "}
            <span>
              {" "}
              <strong>Mentro Covers🤩</strong>
            </span>{" "}
            for your social media.
            <br />
          </Text>
        </Row>
      </Col>
      <Col lg={10} md={12} className="rightCol">
        <img src={Group3} alt="Landing page" style={{ width: "100%" }} />
      </Col>
    </Row>
  )
}
