import React from "react"
import { Typography, Row, Col, Button } from "antd"
import "./Introduction.less"
import MentorsLanding from "../../../assets/mentor/mentorsLanding.svg"
import IntroDesign from "../../../assets/mentor/design.svg"
import Ripple from "../../../utilities/ripple"
import { Link } from "gatsby"
import { logGa } from "../../../services/analytics"
const { Title, Text } = Typography

export default function Introduction() {
  return (
    <Row className="introDiv">
      <img src={IntroDesign} alt="introDesign" className="introDesign" />
      <Col lg={14} md={12} className="leftCol">
        <Title className="title">
          Build your <span>Community </span> and <span>Monetize</span>💰 your XP
        </Title>
        <Text className="text">
          Create and share Learning Content of your niche. Start building your
          Community &amp; be their Mentor today! Be able to monetize with
          in-house services and Earn as you Impact. <br />
        </Text>
        <Link to="/mentor/apply" style={{ fontSize: "1em", margin: "0em" }}>
          <Button
            style={{ overflow: "hidden" }}
            onClick={() => logGa("mentor_landing_apply_button_intro_section")}
          >
            Be a Mentor
            <Ripple />
          </Button>
        </Link>
      </Col>
      <Col lg={10} md={12} className="rightCol">
        <img src={MentorsLanding} alt="Landing page" />
      </Col>
    </Row>
  )
}
