import React from "react"
import { Typography, Col, Row } from "antd"
import "./MentorsFrom.less"
import Google from "../../../assets/mentor/google.svg"
import Microsoft from "../../../assets/mentor/microsoft.svg"
import Amazon from "../../../assets/mentor/amazon.svg"
import Adobe from "../../../assets/mentor/adobe.svg"

const { Title } = Typography

export default function MentorsFrom() {
  return (
    <div className="mentorsFromParent">
      <Row className="mentorsFromDiv">
        <Col sm={24} className="titleDiv">
          <Title className="title">Top Mentors also work at</Title>
        </Col>
        <Col className="companyCol googleCol">
          <img src={Google} alt="google" />
          <span>Google</span>
        </Col>
        <Col className="companyCol microsoftCol">
          <img src={Microsoft} alt="Microsoft" />
          <span>Microsoft</span>
        </Col>
        <Col className="companyCol amazonCol">
          <img src={Amazon} alt="Amazon" />
          <span style={{ marginTop: "-0.5em" }}>Amazon</span>
        </Col>
        <Col className="companyCol adobeCol">
          <img src={Adobe} alt="Adobe" />
          <span>Adobe</span>
        </Col>
      </Row>
    </div>
  )
}
