import React from "react"
import { Collapse, Typography } from "antd"
import "./Faq.less"
import { faqData } from "./faqData.js"
import UpArrow from "../../../assets/mentor/upArrow.svg"
import DownArrow from "../../../assets/mentor/downArrow.svg"
import FaqDesign from "../../../assets/mentor/design.svg"

const { Title, Text } = Typography
const { Panel } = Collapse

export default function Faq() {
  return (
    <div className="faqDiv">
      <img src={FaqDesign} alt="faqDesign" className="faqDesign" />
      <Title className="title">Frequently asked questions</Title>
      <div className="faqQuestionsDiv">
        <Collapse
          bordered={false}
          ghost={true}
          accordion={true}
          expandIcon={({ isActive }) => (
            <img src={isActive ? UpArrow : DownArrow} alt="action" />
          )}
          expandIconPosition="right"
          className="contentCol"
        >
          {faqData.map((faq, ind) => {
            return (
              <Panel
                header={<Title className="question">{faq.question}</Title>}
                key={ind}
                className="site-collapse-custom-panel questionPanel"
              >
                <Text className="answer">{faq.answer}</Text>
              </Panel>
            )
          })}
        </Collapse>
      </div>
    </div>
  )
}
