import React from "react"
import { Row, Typography } from "antd"
import "./Session.less"
import Resume from "../../../assets/mentor/resume.svg"
import Mock from "../../../assets/mentor/mock.svg"
import Placement from "../../../assets/mentor/placement.svg"
import Technical from "../../../assets/mentor/technical.svg"
import SessionDesign from "../../../assets/mentor/design.svg"

const { Title, Text } = Typography

export default function Session() {
  return (
    <div className="sessionDiv">
      <img src={SessionDesign} alt="SessionDesign" className="sessionDesign" />
      <div className="headDiv">
        <Title className="title">Ways you can Impact and Earn</Title>
      </div>
      <Row className="sessions">
        <div className="sessionsCol">
          <img src={Resume} alt="Resume Review" className="sessionImg" />
          <Title className="sessionTitle">LIVE Programs</Title>
          <Text className="sessionText">
            MasterClasses, Bootcamps, and more
          </Text>
        </div>
        <div className="sessionsCol">
          <img src={Mock} alt="Mock Interview" className="sessionImg" />
          <Title className="sessionTitle">Mentorship Sessions</Title>
          <Text className="sessionText">
            Resume Reviews, Roadmaps, Interviews, Doubt sessions
          </Text>
        </div>
        <div className="sessionsCol">
          <img
            src={Placement}
            alt="Placement Roadmaps"
            className="sessionImg"
          />
          <Title className="sessionTitle">Cohorts &amp; Courses</Title>
          <Text className="sessionText">
            Launch your own cohort or hybrid courses, small or large
          </Text>
        </div>
        <div className="sessionsCol">
          <img src={Technical} alt="Technical Doubts" className="sessionImg" />
          <Title className="sessionTitle">Webinars &amp; Workshops</Title>
          <Text className="sessionText">
            Live sessions on topics you choose to educate about
          </Text>
        </div>
      </Row>
    </div>
  )
}
