import React from "react"
import PropTypes from "prop-types"
import { Layout } from "antd"
import Navbar from "../../Shared/Navbar"
import "./Container.less"
import Footer from "../../Shared/Footer"

const { Content } = Layout

const Container = ({ children }) => {
  return (
    <Layout className="containerLayout">
      <Navbar />
      <Content
        style={{
          minHeight: "102vh",
        }}
      >
        {children}
      </Content>
      <Footer />
    </Layout>
  )
}
Container.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Container
