import React from "react"
import { Typography, Row, Col } from "antd"
import "./Fact1.less"
import Group1 from "../../../../assets/mentor/group1.svg"
import FactDesign from "../../../../assets/mentor/design.svg"

const { Title, Text } = Typography

export default function Fact1() {
  return (
    <Row className="fact1Div">
      <img src={FactDesign} alt="factDesign" className="factDesign" />
      <Col lg={12} md={12} className="leftCol">
        <Title className="title">
          Become a <span>Mentor</span>
        </Title>
        <Text className="text">
          Build a community which learns from you. If you are a content creator,
          educator, professional or want to be any of them, start here, start
          Now! <br />
        </Text>
      </Col>
      <Col lg={12} md={12} className="rightCol">
        <img src={Group1} alt="Group 1" />
      </Col>
    </Row>
  )
}
